/* eslint-disable jsx-a11y/role-supports-aria-props */
import {
  Box,
  Button,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  SwipeableDrawer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Modal,
  Breadcrumbs,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchBar from "../../components/SearchBar";
import "../Layout.scss";
import Auth from "../../features/auth/Auth";
import { toggleAuthModal } from "../../reducers/generalSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import { useLocation, useNavigate } from "react-router-dom";
import { Colors } from "../../assets";
import {
  setCredentials,
  temporaryToken,
  getUserLogin,
  userLoginRes,
} from "../../reducers/authSlice";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  removeFromStorage,
  setToStorage,
} from "../../helpers";
import {
  useLazyGetProfileQuery,
  usePostLogoutMutation,
} from "../../service/Auth";
import useAuth from "../../hooks/useAuth";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import CloseIcon from "@mui/icons-material/Close";
import { useLanguageContext } from "../../context/LanguageContext";
import useTranslation from "../../hooks/Translation";

const Header = () => {
  const pageLocation = window.location.pathname;
  const userLogin = useAppSelector(getUserLogin);
  const user = useAuth();
  console.log("user: ", user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const style = generateResponsiveStyle();
  const [getProfile] = useLazyGetProfileQuery();
  const userFromStorage = getFromStorage(STORAGE_KEYS.userData);
  console.log("userFromStorage: ", userFromStorage);

  const translation = useTranslation() as any;
  const { language, changeLanguage } = useLanguageContext();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [path, setPath] = useState("");
  const [translatedPath, setTranslatedPath] = useState("");

  const handleBreadCrumbClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault();
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setSidebarVisible(open);
    };

  const [anchorElSell, setAnchorElSell] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElLang, setAnchorElLang] = React.useState<null | HTMLElement>(
    null
  );
  const handleClickSell = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (user) {
      setAnchorElSell(event.currentTarget);
      // if (user && user?.subscription) {
      //   setAnchorElSell(event.currentTarget);
      // } else {
      //   dispatch(
      //     toggleAuthModal({
      //       isAuthModalVisible: true,
      //       ModalType: "subscription",
      //     })
      //   );
      // }
    } else {
      dispatch(
        toggleAuthModal({
          isAuthModalVisible: true,
          ModalType: "login",
        })
      );
    }
  };

  const handleClickLang = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElLang(event.currentTarget);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const openSell = Boolean(anchorElSell);
  const openLang = Boolean(anchorElLang);
  const handleCloseSell = () => {
    setAnchorElSell(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseLang = () => {
    setAnchorElLang(null);
  };

  const handleLanguageSelect = (language: string) => {
    setSelectedLanguage(language);
    changeLanguage(language);
    setToStorage(STORAGE_KEYS.language, language);
    handleCloseLang();
    window.location.reload();
  };

  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  const formatRouteName = (route: string) => {
    // Replace hyphens with spaces and capitalize each word
    return route
      .replace(/-/g, " ")
      .replace(/\b\w/g, (match) => match.toUpperCase());
  };

  useEffect(() => {
    if (pageLocation) {
      const route = pageLocation.replace("/", "");
      const name = route.split("/");
      let newName = "";
      if (name?.length > 1) {
        newName = formatRouteName(name.slice(0, -1).join(" "));
      } else {
        newName = formatRouteName(route);
      }
      setPath(newName);
    }
  }, [pageLocation]);

  const getLang = getFromStorage(STORAGE_KEYS.language);
  console.log("getLang: ", getLang);

  const change = () => {
    const body = document.body; // Use document.body directly to get the body element

    if (body) {
      const language = getFromStorage(STORAGE_KEYS.language);
      setSelectedLanguage(String(language));
      const isArabic = language === "ar";

      // Remove the opposite class before adding the new one
      body.classList.remove(isArabic ? "english_lang" : "arabic_lang");
      body.classList.add(isArabic ? "arabic_lang" : "english_lang");
    }
  };

  useEffect(() => {
    change();
  }, [getLang]);

  useEffect(() => {
    if (!getLang) {
      setToStorage(STORAGE_KEYS.language, "en");
    }
  }, []);

  const list = () => (
    <Box sx={{ width: "100%" }} role="presentation">
      <ul className="hdr_lstng">
        <li
          onClick={() => {
            setSidebarVisible(false);
            navigate("/");
          }}
        >
          {translation.header.home}
        </li>
        <li
          onClick={() => {
            if (user) {
              setSidebarVisible(false);
              navigate("/services");
            } else {
              setSidebarVisible(false);
              dispatch(
                toggleAuthModal({
                  isAuthModalVisible: true,
                  ModalType: "login",
                })
              );
            }
          }}
        >
          {translation.header.services}
        </li>
        <li
          onClick={() => {
            if (user) {
              setSidebarVisible(false);
              navigate("/my-bids");
            } else {
              setSidebarVisible(false);
              dispatch(
                toggleAuthModal({
                  isAuthModalVisible: true,
                  ModalType: "login",
                })
              );
            }
          }}
        >
          Bids
        </li>
      </ul>
    </Box>
  );

  const getUserDetails = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    try {
      const result = await getProfile({}).unwrap();
      if (result?.statusCode === 200) {
        dispatch(
          setCredentials({
            token: JSON.parse(`${token}`),
            user: result?.data || null,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const { pathname } = useLocation();
  const [postLogout, GetLogoutData] = usePostLogoutMutation();

  const [openLogout, setOpenLogout] = useState(false);

  const handleLogout = async () => {
    try {
      const response = await postLogout({}).unwrap();

      dispatch(
        setCredentials({
          token: null,
          user: null,
        })
      );
      dispatch(
        temporaryToken({
          tempToken: null,
        })
      );
      dispatch(
        userLoginRes({
          userLogin: false,
        })
      );
      removeFromStorage(STORAGE_KEYS.token);
      removeFromStorage(STORAGE_KEYS.userData);
      removeFromStorage(STORAGE_KEYS.tempToken);

      setOpenLogout(false);
      navigate("/");
    } catch (error: any) {
      if (error?.data?.message) {
        errorToast(error?.data?.message || "");
      }
    }
  };

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    const userData = getFromStorage(STORAGE_KEYS.userData);

    if (token) {
      dispatch(
        setCredentials({
          token: JSON.parse(`${token}`),
          user: JSON.parse(`${userData}`) || null,
        })
      );
      dispatch(
        userLoginRes({
          userLogin: true,
        })
      );
      getUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <header className="hdr">
        <div className="hdr_mn">
          <div className="container hdr_top">
            <div className="hdr_lft">
              <button className="burger" type="button">
                <div className="burger-block" onClick={toggleDrawer(true)}>
                  <span />
                  <span />
                  <span />
                </div>
              </button>

              <figure
                className="logo_img hdr_logo"
                onClick={() => navigate("/")}
              >
                <img src="/static/images/logo.png" alt="logo" />
              </figure>
            </div>
            <div className="hdr_srch">
              <div className="search_bar" onClick={() => navigate("/search")}>
                <figure>
                  <img src="/static/images/search-outline.png" alt="" />
                </figure>
                <p>{translation.header.search}</p>
              </div>
            </div>
            <div className="hdr_ryt">
              <div className="users_dv">
                <ul>
                  <ul className="hm_srvcs">
                    <li onClick={() => navigate("/")}>
                      {translation.header.home}
                    </li>
                    <li
                      onClick={() =>
                        user
                          ? navigate("/services")
                          : dispatch(
                              toggleAuthModal({
                                isAuthModalVisible: true,
                                ModalType: "login",
                              })
                            )
                      }
                    >
                      {translation.header.services}
                    </li>

                    {/* <li
                      onClick={() =>
                        user
                          ? navigate("/my-bids")
                          : dispatch(
                              toggleAuthModal({
                                isAuthModalVisible: true,
                                ModalType: "login",
                              })
                            )
                      }
                    >
                      {translation.header.bids}
                    </li> */}
                    {!user || (user && userLogin === false) ? (
                      <li
                        onClick={() =>
                          dispatch(
                            toggleAuthModal({
                              isAuthModalVisible: true,
                              ModalType: "login",
                            })
                          )
                        }
                      >
                        {translation.header.login}
                      </li>
                    ) : null}
                  </ul>

                  <li className="lngLi">
                    <IconButton
                      className="topbar_user_btn"
                      aria-controls={openLang ? "basic-menu-lang" : undefined}
                      aria-haspopup="true"
                      aria-expanded={Boolean(anchorElLang) ? "true" : undefined}
                      onClick={handleClickLang}
                    >
                      <figure>
                        <img
                          src={
                            selectedLanguage === "ar"
                              ? "/static/images/su_flag.webp"
                              : "/static/images/us_flag.png"
                          }
                          alt=""
                        />
                      </figure>
                    </IconButton>
                  </li>
                  {user ? (
                    <li className="userLi">
                      <IconButton
                        className="topbar_user_btn"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                      >
                        <img
                          className={user?.image ? "img_style" : "img_style2"}
                          src={user?.image || "/static/images/userDiv.png"}
                          alt=""
                        />

                        <KeyboardArrowDownIcon />
                      </IconButton>
                    </li>
                  ) : null}
                </ul>
              </div>

              <div className="sellBox">
                <IconButton
                  className="topbar_user_btn"
                  aria-controls={openSell ? "basic-menu-sell" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openSell ? "true" : undefined}
                  onClick={handleClickSell}
                >
                  <figure>
                    <img src="/static/images/add_btn.svg" alt="" />
                  </figure>
                  <p className="sellBtn"> {translation.header.sell}</p>
                </IconButton>
              </div>
            </div>
          </div>
        </div>
        <div className="hdr_srch_mobile">
          <div className="search_bar" onClick={() => navigate("/search")}>
            <figure>
              <img src="/static/images/search-outline.png" alt="" />
            </figure>
            <p> {translation.header.search}</p>
          </div>
        </div>

        <Auth />
        <Menu
          className="userDropdown_menu"
          id="basic-menu-sell"
          anchorEl={anchorElSell}
          open={openSell}
          onClose={handleCloseSell}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 10,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            style={{ color: Colors.secondary }}
            value="Profile"
            onClick={() => navigate("/post-for-sell")}
          >
            {translation.header.postSell}
          </MenuItem>
          <MenuItem
            style={{ color: Colors.secondary }}
            value="Chat"
            onClick={() => navigate("/post-for-auction")}
          >
            {translation.header.postAuction}
          </MenuItem>

          <MenuItem
            style={{ color: Colors.secondary }}
            value="Chat"
            onClick={() => navigate("/post-for-offer")}
          >
            {translation.header.postOffer}
          </MenuItem>
        </Menu>

        <Menu
          className="userDropdown_menu"
          id="basic-menu-lang"
          anchorEl={anchorElLang}
          open={openLang}
          onClose={handleCloseLang}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 10,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            style={{ color: Colors.secondary }}
            value="Profile"
            onClick={() => handleLanguageSelect("ar")}
          >
            <div className="drm">
              <figure>
                <img src="/static/images/su_flag.webp" alt="" />
              </figure>
              <p>{translation.header.arabic}</p>
            </div>
          </MenuItem>
          <MenuItem
            style={{ color: Colors.secondary }}
            value="Chat"
            onClick={() => handleLanguageSelect("en")}
          >
            <div className="drm">
              <figure>
                <img src="/static/images/us_flag.png" alt="" />
              </figure>
              <p>English</p>
            </div>
          </MenuItem>
        </Menu>
        <Menu
          className="userDropdown_menu"
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 10,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            style={{ color: Colors.secondary }}
            value="Profile"
            onClick={() => navigate("/profile")}
          >
            {translation.header.profile}
          </MenuItem>

          <MenuItem
            style={{ color: Colors.secondary }}
            value="Bids"
            onClick={() =>
              user
                ? navigate("/my-bids")
                : dispatch(
                    toggleAuthModal({
                      isAuthModalVisible: true,
                      ModalType: "login",
                    })
                  )
            }
          >
            {translation.header.bids}
          </MenuItem>
          <MenuItem
            style={{ color: Colors.secondary }}
            value="Bids"
            onClick={() => {
              if (user) {
                navigate(`/profile`, { state: "sellPost" });
                handleClose();
              } else {
                dispatch(
                  toggleAuthModal({
                    isAuthModalVisible: true,
                    ModalType: "login",
                  })
                );
              }
            }}
          >
            {translation.profile.myListing}
          </MenuItem>

          <MenuItem
            style={{ color: Colors.secondary }}
            value="Bids"
            onClick={() => {
              if (user) {
                navigate(`/profile`, { state: "auctionPost" });
                handleClose();
              } else {
                dispatch(
                  toggleAuthModal({
                    isAuthModalVisible: true,
                    ModalType: "login",
                  })
                );
              }
            }}
          >
            {translation.profile.myAuctionPost}
          </MenuItem>

          <MenuItem
            style={{ color: Colors.secondary }}
            value="Bids"
            onClick={() => {
              if (user) {
                navigate(`/profile`, { state: "wishlist" });
                handleClose();
              } else {
                dispatch(
                  toggleAuthModal({
                    isAuthModalVisible: true,
                    ModalType: "login",
                  })
                );
              }
            }}
          >
            {translation.profile.myWishlist}
          </MenuItem>

          <MenuItem
            style={{ color: Colors.secondary }}
            value="Bids"
            onClick={() => {
              if (user) {
                navigate(`/profile`, { state: "earnings" });
                handleClose();
              } else {
                dispatch(
                  toggleAuthModal({
                    isAuthModalVisible: true,
                    ModalType: "login",
                  })
                );
              }
            }}
          >
            {translation.profile.myEarning}
          </MenuItem>

          <MenuItem
            style={{ color: Colors.secondary }}
            value="Chat"
            onClick={() => navigate("/chat")}
          >
            {translation.header.chat}
          </MenuItem>
          <MenuItem
            style={{ color: Colors.secondary }}
            value="YEAR"
            onClick={() => navigate("/notifications")}
          >
            {translation.header.notifications}
          </MenuItem>
          <MenuItem
            style={{ color: Colors.secondary }}
            value="YEAR"
            onClick={() => {
              setAnchorEl(null);
              setOpenLogout(true);
            }}
          >
            {translation.header.logout}
          </MenuItem>
        </Menu>
        <div>
          <SwipeableDrawer
            className="drwr"
            anchor="left"
            open={sidebarVisible}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            {list()}
          </SwipeableDrawer>
        </div>
        <Modal
          open={openLogout}
          onClose={() => setOpenLogout(false)}
          hideBackdrop
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="AuthWrap">
              <div className="cross ">
                <CloseIcon
                  style={{ color: "red" }}
                  onClick={() => setOpenLogout(false)}
                />
              </div>
              <h2
                style={{
                  textAlign: "center",
                  marginTop: "-20px",
                  fontSize: "22px",
                }}
              >
                {translation.header.logoutWarning}
              </h2>
              <div className="flexDiv">
                <Button
                  className="yesBtn"
                  sx={{ color: "#252525", border: "1px solid #204e33" }}
                  onClick={() => {
                    setOpenLogout(false);
                    handleLogout();
                  }}
                >
                  {translation.header.yes}
                </Button>

                <Button
                  sx={{ color: "#252525", border: "1px solid #204e33" }}
                  onClick={() => setOpenLogout(false)}
                >
                  {translation.header.no}
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      </header>
      <div className={pageLocation === "/" ? "no_brdcrmbs" : "mn_brdcrmbs"}>
        <div className="container">
          <div
            role="presentation"
            onClick={(e) => handleBreadCrumbClick(e)}
            className="showBred"
          >
            <Breadcrumbs
              key="1"
              aria-label="breadcrumb"
              separator={path === "" ? "" : "›"}
            >
              <div
                onClick={() => {
                  navigate("/");
                }}
                className={"brdcrmb"}
              >
                Home
              </div>

              <Typography
                key="2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#820000",
                  textTransform: "capitalize",
                }}
                color="text.primary"
              >
                {path}
              </Typography>
            </Breadcrumbs>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
