import React from "react";
import Layout from "../../layout";
import "./bookingDetail.scss";
import { Button, CarNumberPlate } from "../../components";
import useTranslation from "../../hooks/Translation";

const BookingDetail = () => {
  const translation = useTranslation() as any;
  return (
    <Layout>
      <div className="wrapCls ">
        <div className="comment_card">
          <div className="container">
            <div className="heading">
              <h1 className="prdct_dtl_hdng">Booking Detail</h1>
              <div className="won_div">
                <p>Won</p>
              </div>
            </div>
            <div className="booking_container">
              <div className="left_div">
                <div className="dLeft">
                  <CarNumberPlate />
                </div>
                <div className="details">
                  <div className="top_section">
                    <div className="post_id">
                      <p className="id">Post ID: 216411722</p>
                      <p className="dsa">DSA 228</p>
                    </div>
                    <div className="bbid">
                      <p className="bid">Last Bid</p>
                      <p className="price">15,000 {translation.Globals.sar}</p>
                    </div>
                  </div>
                  <div className="location">
                    <figure>
                      <img src="/static/images/location.svg" alt="location" />
                    </figure>

                    <p>Dammam, Saudi Arabia</p>
                  </div>
                  <div className="auction">
                    <div style={{ display: "flex", gap: "6px" }}>
                      <p className="time">Ending Auction Time:</p>
                      <p className="count">02h 01m 45s</p>
                    </div>
                    <p className="date">12 July, 2023</p>
                  </div>
                </div>
              </div>
              <div className="right_div">
                <div className="seller_card">
                  <p style={{ fontSize: "17px" }}>Seller Detail</p>
                  <div className="top_sec">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <figure>
                        <img
                          src="/static/images/userDiv.png"
                          className="user_img"
                        />
                      </figure>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "14px",
                          }}
                        >
                          <h3>Shoaib Malik</h3>
                          <div className="rating">
                            <img
                              src="/static/images/star.png"
                              className="star"
                            />
                            <span>4.8</span>
                          </div>
                        </div>

                        <p className="fnt_sz" style={{ fontSize: "17px" }}>
                          Dammam, Saudi Arabia
                        </p>
                      </div>
                    </div>
                    <figure>
                      <img
                        src="/static/images/userDiv.png"
                        className="user_img"
                      />
                    </figure>
                  </div>
                  <div className="horizontal_line"></div>
                  <p style={{ fontSize: "17px" }}>Customer Detail</p>
                  <div className="profile">
                    <p>Full Name</p>
                    <p>Abdul Hussian</p>
                  </div>
                  <div className="profile">
                    <p>Phone No.</p>
                    <p>+971 0 56734321</p>
                  </div>
                  <div className="profile">
                    <p>Email</p>
                    <p>admin@gmail.com</p>
                  </div>
                  <div className="profile">
                    <p>Address</p>
                    <p>Lower Manhattan</p>
                  </div>

                  <div className="horizontal_line"></div>

                  <p style={{ fontSize: "17px" }}>Payment Details</p>

                  <div className="bidding">
                    <p className="bid_won">Bid Won</p>
                    <p className="bid_amnt">20,000 {translation.Globals.sar}</p>
                  </div>

                  <div className="payment_card">
                    <div className="card">
                      <figure>
                        <img src="/static/images/visa.png" />
                      </figure>
                      <p style={{ fontSize: "15px" }}>Paid by card</p>
                    </div>
                    <div style={{ alignItems: "flex-end", display: "flex" }}>
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#204E33",
                        }}
                      >
                        View Receipt
                      </p>
                    </div>
                  </div>
                  <div style={{ paddingTop: "30px", paddingBottom: "20px" }}>
                    <Button value="Rate Now" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BookingDetail;
