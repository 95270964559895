import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { AddAgentModal, AddServiceModal, Button } from "../../components";
import { Loader, errorToast } from "../../helpers";
import { AllServiceType, SellService } from "../../types/General";
import {
  useAddAgentToServiceMutation,
  useAddMyServiceMutation,
  useLazySellServiceListByIdQuery,
  useLazySellServiceListQuery,
} from "../../service/posts";
import { useLocation, useParams } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import useTranslation from "../../hooks/Translation";

const AgentForService = () => {
  const { id } = useParams();
  const location = useLocation();
  const translation = useTranslation();

  const [allMyServiceList] = useLazySellServiceListQuery();
  const [addServiceAgent, addServiceAgentData] = useAddAgentToServiceMutation();

  const [serviceId, setServiceId] = useState<string[]>([]);
  const [addServiceClick, serAddServiceClick] = useState(false);
  const [serviceData, serServiceData] = useState<SellService | null>(null);
  const [selectedId, setSelectedId] = useState<AllServiceType | null>(null);

  const getServiceById = async (id: string) => {
    try {
      const response = await allMyServiceList({}).unwrap();
      if (response?.statusCode === 200) {
        const filteredService = response?.data?.service.find(
          (item) => item._id === id
        );
        serServiceData(filteredService || null);
        setServiceId(
          filteredService?.userserviceagents?.map(
            (item) => item?.agentId?._id
          ) || []
        );
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const handleDeleteAgent = async (id: string) => {
    if (serviceId?.includes(id)) {
      setServiceId((serviceId) => serviceId.filter((s) => s !== id));
      const body = {
        serviceId: serviceData?.serviceId,
        agents: serviceId?.filter((s) => s !== id),
      };

      try {
        const response = await addServiceAgent(body).unwrap();
        if (response?.statusCode === 200) {
          window.location.reload();
          // if (id) {
          //   try {
          //     const response = await allMyServiceList({}).unwrap();
          //     if (response?.statusCode === 200) {
          //       const filteredService = response?.data?.service.find(
          //         (item) => item?._id === id
          //       );
          //       console.log("filteredService: ", filteredService);

          //       serServiceData(filteredService || null);
          //       setServiceId(
          //         filteredService?.userserviceagents?.map(
          //           (item) => item?.agentId?._id
          //         ) || []
          //       );
          //     }
          //   } catch (error: any) {
          //     errorToast(error?.data?.message || "");
          //   }
          // }
        }
      } catch (error: any) {
        if (error?.data?.message) {
          errorToast(error?.data?.message || "");
        }
      }
    } else {
      setServiceId((serviceId) => [...serviceId, id]);
    }
  };

  const handleAddServiceAgent = async () => {
    const body = {
      serviceId: serviceData?.serviceId,
      agents: serviceId,
    };
    if (serviceId?.length) {
      try {
        const response = await addServiceAgent(body).unwrap();
        if (response?.statusCode === 200) {
          serAddServiceClick(false);
          if (id) {
            getServiceById(id);
          }
        }
      } catch (error: any) {
        if (error?.data?.message) {
          errorToast(error?.data?.message || "");
        }
      }
    } else {
      errorToast(translation.toast_messages.selectAgent);
    }
  };

  useEffect(() => {
    if (id) {
      getServiceById(id);
    }
  }, []);

  return (
    <Layout>
      <div className="wrapCls">
        <div className="comment_card">
          <div className="container">
            <div className="flexJus">
              <h1 className="prdct_dtl_hdng">
                {serviceData?.serviceType || ""} (translation.Globals.sar{" "}
                {serviceData?.serviceFees || 0})
              </h1>
              <div className="service_btn">
                <Button
                  value={translation.service.addAgent}
                  onClick={() => serAddServiceClick(true)}
                />
              </div>
            </div>
            <div className="serviceList">
              <ul>
                {serviceData?.userserviceagents?.length ? (
                  serviceData?.userserviceagents?.map((item) => (
                    <li key={item?.agentId?._id}>
                      <div>
                        <h3>{item?.agentId?.agentName || ""}</h3>
                        <p>
                          {item?.agentId?.countryCode || ""}{" "}
                          {item?.agentId?.phone || ""}
                        </p>
                      </div>
                      <div
                        className="crossIcon"
                        onClick={() => handleDeleteAgent(item?.agentId?._id)}
                      >
                        <ClearIcon />
                      </div>
                    </li>
                  ))
                ) : (
                  <div className="noData">
                    <figure>
                      <img src="/static/images/noData.webp" alt="" />
                    </figure>
                    <h3> {translation.error.noAgent}</h3>
                  </div>
                )}
              </ul>
            </div>
            {/* <div className="btn-payment">
            <Button value="Request for a service" />
          </div> */}
          </div>
        </div>
      </div>
      <AddAgentModal
        open={addServiceClick}
        setOpen={serAddServiceClick}
        handleSubmit={handleAddServiceAgent}
        setServiceId={setServiceId}
        serviceId={serviceId}
        serviceData={serviceData}
      />
    </Layout>
  );
};

export default AgentForService;
