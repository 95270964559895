import React from "react";
import Layout from "../../layout";
import "../contactUs/Cms.scss";
import { useAppSelector } from "../../hooks/store";
import { getCms } from "../../reducers/generalSlice";
import useTranslation from "../../hooks/Translation";
import { getFromStorage, STORAGE_KEYS } from "../../helpers";

const PrivacyPolicy = () => {
  const getCmsData = useAppSelector(getCms);
  const translation = useTranslation();
  const language = getFromStorage(STORAGE_KEYS.language);

  return (
    <Layout>
      <div className="wrapCls">
        <div className="comment_card">
          <div className="common_policy privicy">
            <div className="container">
              <div className="privt_one">
                <h1>{translation.footer.privacy}</h1>
                <p
                  className={getCmsData ? "about_txt" : "about_txt2"}
                  dangerouslySetInnerHTML={{
                    __html: getCmsData
                      ? language === "en"
                        ? getCmsData?.privacyPolicy
                        : getCmsData?.privacyPolicy_ar
                      : translation.Globals.no_info,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
