import React, { useEffect, useState } from "react";
import cardData from "../../data/ProductData.json";
import { CarPlateCard } from "../../components";
import { useNavigate } from "react-router-dom";
import { Dashboard } from "../../types/General";
import useTranslation from "../../hooks/Translation";

type props = {
  dashboardData: Dashboard | null;
  loading: boolean;
};

const QuadruplePost = ({ dashboardData, loading }: props) => {
  const translation = useTranslation();
  const navigate = useNavigate();
  const [favItems, setFavItems] = useState<any[]>([]);
  const [likeItems, setLikeItems] = useState<any[]>([]);

  const checkFavProperties = () => {
    const binaryData = dashboardData?.quadruple || [];
    const fav_data = binaryData
      .filter((item: any) => item?.isWishlistByMe)
      .map((item: any) => item);
    const like_data = binaryData
      .filter((item: any) => item?.isLikedByMe)
      .map((item: any) => item);
    setLikeItems(like_data);
    setFavItems(fav_data);
  };

  useEffect(() => {
    checkFavProperties();
  }, [dashboardData?.quadruple]);

  return (
    <section className="prodcts_sc container">
      <div className="prdct_hdngs ">
        <h2>{translation?.home.quadruple}</h2>
        {dashboardData?.quadruple?.length &&
        dashboardData?.quadruple?.length > 5 ? (
          <p
            onClick={() =>
              navigate("/see-all", {
                state: {
                  key: translation.home.quadruple,
                  type: "quadruple",
                },
              })
            }
          >
            {translation?.home.seeAll}
          </p>
        ) : null}
      </div>

      <div className="crds_sec">
        {!loading && !dashboardData?.quadruple?.length ? (
          <div className="noData">
            <figure>
              <img src="/static/images/noData.webp" alt="" />
            </figure>
            <h3>{translation?.error.noData}</h3>
          </div>
        ) : dashboardData?.quadruple?.length ? (
          dashboardData?.quadruple
            ?.slice(0, 5)
            .map((item) => (
              <CarPlateCard
                category={item?.categoryData?.categoryName}
                posttitle={item?.posttitle}
                price={item?.price}
                carPlatenumber={item?.carPlatenumber}
                _id={item?._id}
                setFavItems={setFavItems}
                favItems={favItems}
                item={item}
                likeItems={likeItems}
                setLikeItems={setLikeItems}
              />
            ))
        ) : null}
      </div>
    </section>
  );
};

export default QuadruplePost;
