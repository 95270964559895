import React, { ChangeEvent, useEffect, useState } from "react";
import Layout from "../../layout";
import { CarNumberPlate, InputField } from "../../components";
import * as Yup from "yup";
import { useFormik } from "formik";
import OTPInput from "react-otp-input";
import {
  Backdrop,
  Box,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { Button } from "../../components";
import FormLabel from "@mui/material/FormLabel";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAddAuctionPostMutation,
  useAddSellPostMutation,
  useLazyGetAllCateQuery,
  useLazyGetAuctionPostDetailByIdQuery,
  useLazyGetPostDetailByIdQuery,
  useLazyGetPostServiceQuery,
  useLazySellServiceListQuery,
  useUpdateAuctionPostMutation,
  useUpdateSellPostMutation,
} from "../../service/posts";
import {
  Category,
  ListedPost,
  PostService,
  SellService,
  SettingRes,
} from "../../types/General";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import PhoneInput from "react-phone-input-2";
import {
  isNumber,
  isString,
  letters,
  number,
  saudiCitiesList,
} from "../../utils/validation";
import moment from "moment";
import { generateResponsiveStyle, otpStyle } from "../../utils/AuthModalStyle";
import useTranslation from "../../hooks/Translation";
import { useLazyGetSettingsQuery } from "../../service/Common";

const AddAuction = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const translation = useTranslation();
  const style = generateResponsiveStyle();
  const language = getFromStorage(STORAGE_KEYS.language);
  const [allCategory] = useLazyGetAllCateQuery();
  const [postService] = useLazyGetPostServiceQuery();
  const [updateAuctionPost, updateSellPostData] =
    useUpdateAuctionPostMutation();
  const [addAuctionPost, { isLoading }] = useAddAuctionPostMutation();
  const [getPostDetailById] = useLazyGetAuctionPostDetailByIdQuery();
  const [sellServiceMethod] = useLazySellServiceListQuery();
  const [getSettingMutation] = useLazyGetSettingsQuery();

  const [otp, setOtp] = useState("");
  const [category, setCategory] = useState("");
  const [address, setAddress] = useState("");
  const [inputs, setInputs] = useState<string[]>(["", "", ""]);
  const [priceType, setPricetype] = useState("Fixed");
  const [error, setError] = useState(false);
  const [category1, setCategory1] = useState<Category[]>([]);
  const [service, setService] = useState<PostService[]>([]);
  const [serviceId, setServiceId] = useState<string[]>([]);
  const [selectedId, setSelectedId] = useState("");
  const [listedPost, setListedPost] = useState<ListedPost | null>(null);
  const [teaserOpen, setTeaserOpen] = useState(false);

  const [agentServiceArr, setAgentServiceArr] = useState<any>([]);
  const [agentsListing, setAgentsListing] = useState<SellService[]>([]);
  const [agentService, setAgentsService] = useState<string[]>([]);
  // const validChars = /[ا-وىبحدرسصطعقلمنهـABDJRSLTXTEGKZNHUV]/;
  const validChars = /[aAbBjJdDrRsSxXtTeEgGkKlLzZnNhHuUvV'ابحدرسصطعقكلمنهـوى]/;

  const arabicToEnglishMap: { [key: string]: string } = {
    ا: "A",
    ب: "B",
    ح: "J",
    د: "D",
    ر: "R",
    س: "S",
    ص: "X",
    ط: "T",
    ع: "E",
    ق: "G",
    ك: "K",
    ل: "L",
    م: "Z",
    ن: "N",
    هـ: "H",
    و: "U",
    ى: "V",
  };

  const handleChangePriceType = (event: any) => {
    setPricetype(event.target.value);
  };

  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace" && index > 0 && inputs[index] === "") {
      // Move to the previous input if backspace is pressed on an empty input
      const prevInput = document.getElementById(`input-${index - 1}`);
      if (prevInput) {
        (prevInput as HTMLInputElement).focus();
      }
    }
  };

  const handleCheckboxChange = (agentId: string, serviceId: string) => {
    const updatedArr = [...agentServiceArr];
    const index = updatedArr.findIndex((item) => item?.serviceId === serviceId);
    if (index !== -1) {
      // Service ID exists, update agent IDs
      const agentIndex = updatedArr[index].agents.indexOf(agentId);
      if (agentIndex !== -1) {
        updatedArr[index].agents.splice(agentIndex, 1); // Remove agent ID
      } else {
        updatedArr[index].agents.push(agentId); // Add agent ID
      }
    } else {
      // Service ID doesn't exist, add new object
      updatedArr.push({
        serviceId: serviceId,
        agents: [agentId],
        agentDetails: [],
        serviceType: null,
        serviceFees: null,
      });
    }
    setAgentServiceArr(updatedArr);
  };

  const getSellServicesList = async () => {
    try {
      const response = await sellServiceMethod({}).unwrap();
      if (response?.statusCode === 200) {
        setAgentsListing(response?.data?.service || []);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  const handleChangeAddress = (event: SelectChangeEvent) => {
    setAddress(event.target.value as string);
  };

  const handleChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    let { value } = event.target;

    // Only allow valid Arabic or English characters
    value = value
      .split("")
      .filter((char) => validChars.test(char))
      .map((char) => arabicToEnglishMap[char] || char.toUpperCase())
      .join("");

    const newInputs = [...inputs];
    newInputs[index] = value; // Already converted to uppercase and translated if necessary
    setInputs(newInputs);

    // Move focus to the next input if the current input is not empty and not the last input
    if (value !== "" && index < inputs.length - 1) {
      const nextInput = document.getElementById(
        `input-${index + 1}`
      ) as HTMLInputElement | null;
      if (nextInput) nextInput.focus();
    }
  };

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    formik.setFieldValue("countryCode", country?.dialCode);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      meaningOfPlate: listedPost?.meaningOfPlate || "",
      phone: listedPost?.postPhone || "",
      countryCode: "+966",
      price: listedPost?.price || "",
      startingBid: listedPost?.startingBid || "",
      startDate: listedPost?.bidingStartTime
        ? moment(listedPost?.bidingStartTime).format("YYYY-MM-DD")
        : "",
      endDate: listedPost?.bidingEndTime
        ? moment(listedPost?.bidingEndTime).format("YYYY-MM-DD")
        : "",
      termsCondition: false,
    },
    validationSchema: Yup.object({
      termsCondition: Yup.boolean(),
      startDate: Yup.string().required(translation.validations.required_field),
      endDate: Yup.string().required(translation.validations.required_field),
      meaningOfPlate: Yup.string()
        .min(2, translation.validations.min_two)
        .max(80, translation.validations.max_Eighty),
      price: Yup.string().required(translation.validations.required_field),
      startingBid: Yup.string().required(
        translation.validations.required_field
      ),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      if (!category || !inputs?.[0]?.length || !otp || !address) {
        return;
      }

      // if (formik.values.startDate < formik.values.endDate) {
      //   errorToast(translation.enterValidDate);
      //   return;
      // }

      let body = {
        posttitle: inputs.join(""),
        price: formik.values.price,
        carPlatenumber: otp,
        category: category,
        postLocation: address,
        postPhone: formik.values.phone,
        postCountryCode: "+966",
        typeOfPrice: priceType,
        meaningOfPlate: formik.values.meaningOfPlate,
        role: "AUCTION",
        startingBid: formik.values.startingBid,
        // endingBid: formik.values.end,
        bidingStartTime: formik.values.startDate,
        bidingEndTime: formik.values.endDate,
        serviceId: serviceId,
        services: agentServiceArr,
        reservePrice: "",
      };
      console.log(body, "body");

      if (id) {
        try {
          const response = await updateAuctionPost({
            body,
            id,
          }).unwrap();
          if (response?.statusCode === 200) {
            successToast(translation.toast_messages.postUpdated);
            navigate("/profile", { state: "auctionPost" });
          } else {
            errorToast(response?.message || "");
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
        }
      } else {
        try {
          const response = await addAuctionPost(body).unwrap();
          if (response?.statusCode === 200) {
            successToast(translation.toast_messages.postAdded);
            navigate("/profile", { state: "auctionPost" });
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
        }
      }

      formik.setSubmitting(false);
    },
  });

  const getPlateDetailById = async (id: string) => {
    try {
      const response = await getPostDetailById({ id }).unwrap();
      if (response?.statusCode === 200) {
        setListedPost(response?.data);
        setInputs(response?.data?.posttitle.split(""));
        setOtp(response?.data?.carPlatenumber || "");
        setCategory(response?.data?.categoryData?._id || "");
        setAddress(response?.data?.postLocation || "");
        setPricetype(response?.data?.typeOfPrice || "");
        setServiceId(response?.data?.serviceDetails?.map((item) => item?._id));

        //
        const updatedAgentServiceArr = response?.data?.services?.map(
          (item) => ({
            serviceId: item?.serviceId,
            agents: item?.agentDetails?.map((agent: any) => agent?.agentId),
          })
        );
        setAgentServiceArr(updatedAgentServiceArr);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };
  const [setting, setSetting] = useState<SettingRes | null>(null);
  const [auctionStartDateAdmin, setAuctionStartDateAdmin] = useState("");
  const [auctionEndDateAdmin, setAuctionEndDateAdmin] = useState("");

  const getSetting = async () => {
    try {
      const response = await getSettingMutation({}).unwrap();
      if (response?.statusCode === 200) {
        if (response?.data) {
          setSetting(response?.data);
          const startAuction = new Date(response?.data?.auctionStartDate);
          const endAuction = new Date(response?.data?.auctionEndDate);

          setAuctionStartDateAdmin(startAuction.toISOString().split("T")[0]);
          setAuctionEndDateAdmin(endAuction.toISOString().split("T")[0]);
        }
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getPlateDetailById(id);
    }
  }, []);

  const getAllCate = async () => {
    try {
      const response = await allCategory({}).unwrap();
      if (response?.statusCode === 200) {
        setCategory1(response?.data);
        setSelectedId(response?.data?.[0]?._id || "");
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const getPostService = async () => {
    try {
      const response = await postService({}).unwrap();
      if (response?.statusCode === 200) {
        setService(response?.data || []);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (!id && setting) {
      setTeaserOpen(true);
    }
  }, [setting]);

  useEffect(() => {
    getAllCate();
    getSetting();
    getPostService();
    getSellServicesList();
  }, []);

  return (
    <Layout>
      <Loader isLoad={isLoading || updateSellPostData?.isLoading} />
      <div className="wrapCls">
        <div className="comment_card">
          <div className="container">
            <div className="main_add">
              <h1 className="prdct_dtl_hdng">
                {id
                  ? translation.posts.auctionPostUpdate
                  : translation.posts.auctionPostAdd}
              </h1>
              <div className="plate_box">
                <ul className="pLft">
                  <li>
                    {otp
                      ? otp
                          ?.split("")
                          .reverse()
                          /* @ts-ignore */
                          ?.map((item: string) => number[`${item}`])
                          ?.join(" ")
                      : "XXXX"}
                  </li>
                  <li>
                    {inputs[0] === ""
                      ? "XXX"
                      : inputs
                          /* @ts-ignore */
                          ?.map(
                            /* @ts-ignore */
                            (item: string) => letters[`${item?.toLowerCase()}`]
                          )
                          ?.reverse()
                          .join(" ")}
                  </li>
                  <li>{otp ? otp.split("").join(" ") : "XXXX"}</li>
                  <li>{inputs[0]?.length ? inputs.join(" ") : "XXX"}</li>
                </ul>
                <div
                  className="pRyt"
                  style={{
                    background: category === selectedId ? "#3c82c8" : "",
                  }}
                >
                  <figure>
                    <img src="/static/images/lowhate_icon.png" alt="" />
                  </figure>
                  <p>السعودية</p>
                  <div className="ksa">
                    <h4>K</h4>
                    <h4>S</h4>
                    <h4>A</h4>
                  </div>
                  {category === selectedId ? (
                    <figure className="arrow">
                      <img src="/static/images/downArrow.svg" />
                    </figure>
                  ) : (
                    <div className="greenDot" />
                  )}
                </div>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div>
                  <div className="addOtp">
                    <p>{translation.posts.postTitle}</p>
                    <div className="plate_board">
                      {inputs.map((value, index) => (
                        <input
                          className="plate_no"
                          key={index}
                          id={`input-${index}`}
                          maxLength={1}
                          value={value}
                          onChange={(event) => handleChange(index, event)}
                          onKeyDown={(event) => handleKeyDown(index, event)}
                          disabled={!!id}
                        />
                      ))}
                    </div>
                    {error && !inputs?.[0]?.length ? (
                      <h6 className="err_msg">
                        {" "}
                        {translation.validations.required_field}
                      </h6>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="addOtp cr_nmbr">
                    <p>{translation.posts.carPlateNum}</p>
                    <OTPInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      // renderInput={(props) => <input {...props} />}
                      inputStyle={otpStyle}
                      inputType="tel"
                      containerStyle={{ width: "100%" }}
                      renderInput={(props, index) => (
                        <input {...props} disabled={!!id} /> // Disable the OTP input field if ID is present
                      )}
                    />
                    {error && !otp ? (
                      <h6 className="err_msg">
                        {translation.validations.required_field}
                      </h6>
                    ) : (
                      ""
                    )}
                    <div className="addOtp">
                      <p>{translation.posts.meaningOfPlate}</p>
                      <InputField
                        disabled={
                          id && formik.values.meaningOfPlate ? true : false
                        }
                        placeholder=""
                        name="meaningOfPlate"
                        id="meaningOfPlate"
                        value={formik.values.meaningOfPlate}
                        inputProps={{
                          maxLength: 50,
                        }}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else if (isString(val.target.value)) {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        helperText={
                          formik.touched.meaningOfPlate &&
                          formik.errors.meaningOfPlate
                        }
                      />
                      <p className="meaningCount">{`${formik.values.meaningOfPlate?.length}/50`}</p>
                    </div>
                    <div className="addOtp">
                      <p>
                        {" "}
                        <p>{translation.home.category}</p>
                      </p>
                      <Select
                        fullWidth
                        value={category}
                        onChange={handleCategoryChange}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          {translation.Globals.select}
                        </MenuItem>

                        {category1?.length
                          ? category1?.map((item) => (
                              <MenuItem key={item?._id} value={item?._id}>
                                {language === "en"
                                  ? item?.categoryName || ""
                                  : item?.categoryName_ar || ""}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                      {error && !category ? (
                        <h6 className="err_msg">
                          {" "}
                          {translation.validations.required_field}
                        </h6>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="addOtp">
                      <p>{translation.Globals.phone_number}</p>
                      <PhoneInput
                        country={"in"}
                        value={formik.values.countryCode + formik.values.phone}
                        onChange={(phone: any, country: any) =>
                          handleChangePhone(phone, country)
                        }
                        onBlur={formik.handleBlur}
                        placeholder="0 (000) 000-000"
                        enableSearch={false}
                        inputStyle={{
                          width: "100%",
                          backgroundColor: "#EBEBEB",
                        }}
                        onlyCountries={["in", "sa"]}
                      />
                      {formik.touched.phone && formik.errors.phone ? (
                        <h6 className="err_msg">
                          {formik.touched.phone && formik.errors.phone}
                        </h6>
                      ) : (
                        ""
                      )}

                      <div style={{ marginBottom: 10 }} />
                    </div>
                    <div className="addOtp">
                      <p>{translation.Globals.address}</p>
                      <Select
                        fullWidth
                        value={address}
                        onChange={handleChangeAddress}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          {translation.Globals.select}
                        </MenuItem>

                        {saudiCitiesList?.map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                      {error && !address ? (
                        <h6 className="err_msg">
                          {" "}
                          {translation.validations.required_field}
                        </h6>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="addOtp">
                    <p> {translation.Globals.price}</p>
                    <InputField
                      placeholder={translation.posts.pricePlate}
                      name="price"
                      id="price"
                      value={formik.values.price}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      helperText={formik.touched.price && formik.errors.price}
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                  </div>

                  <div className="addOtp">
                    <p>{translation.posts.startedBid}</p>
                    <InputField
                      placeholder={translation.posts.startedBid}
                      name="startingBid"
                      id="startingBid"
                      value={formik.values.startingBid}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.startingBid && formik.errors.startingBid
                      }
                    />
                  </div>

                  <div className="startend">
                    <div className="addOtp1">
                      <p>{translation.posts.bidStartDate}</p>
                      <TextField
                        inputProps={{
                          min: auctionStartDateAdmin,
                          max: auctionEndDateAdmin,
                        }}
                        onKeyDown={(e) => e.preventDefault()}
                        hiddenLabel
                        placeholder={translation.posts.bidStartDate}
                        fullWidth
                        type="date"
                        variant="outlined"
                        className="text_field"
                        name="startDate"
                        id="startDate"
                        value={formik.values.startDate}
                        onChange={formik.handleChange}
                        helperText={
                          formik.touched.startDate && formik.errors.startDate
                        }
                      />
                    </div>

                    <div className="addOtp1">
                      <p>Bidding End Date</p>
                      <TextField
                        inputProps={{
                          min: formik.values.startDate
                            ? formik.values.startDate
                            : auctionStartDateAdmin,
                          max: auctionEndDateAdmin,
                        }}
                        onKeyDown={(e) => e.preventDefault()}
                        hiddenLabel
                        placeholder={translation.posts.bidStartDate}
                        fullWidth
                        type="date"
                        variant="outlined"
                        className="text_field"
                        name="endDate"
                        id="endDate"
                        value={formik.values.endDate}
                        onChange={formik.handleChange}
                        helperText={
                          formik.touched.endDate && formik.errors.endDate
                        }
                      />
                    </div>
                  </div>

                  {/* service and agents */}

                  {agentsListing?.length ? (
                    <div className="agentLinting">
                      <h3>{translation.posts.serviceAgent}</h3>
                      <ul>
                        {agentsListing?.map((item) => (
                          <>
                            {item?.userserviceagents?.length ? (
                              <li key={item?._id}>
                                <p>{item?.serviceType || ""}</p>

                                <FormGroup className="agentcheck ">
                                  {item?.userserviceagents?.length
                                    ? item?.userserviceagents?.map((row) => (
                                        <>
                                          <FormControlLabel
                                            key={row?.agentId?._id}
                                            control={
                                              <Checkbox
                                                checked={agentServiceArr.some(
                                                  (service: any) =>
                                                    service?.serviceId ===
                                                      item?.serviceId &&
                                                    service?.agents?.includes(
                                                      row.agentId._id
                                                    )
                                                )}
                                              />
                                            }
                                            label={
                                              row?.agentId?.agentName || ""
                                            }
                                            onChange={(e) =>
                                              handleCheckboxChange(
                                                row?.agentId?._id,
                                                item?.serviceId
                                              )
                                            }
                                          />
                                          <br />
                                        </>
                                      ))
                                    : null}
                                </FormGroup>
                              </li>
                            ) : null}
                          </>
                        ))}
                      </ul>
                    </div>
                  ) : null}

                  {/* service and agents */}

                  <FormGroup className="trmscndtn_btnui ">
                    <FormControlLabel
                      label=""
                      control={<Checkbox />}
                      checked={formik.values.termsCondition}
                      name="termsCondition"
                      onChange={formik.handleChange}
                    />
                    <p>
                      {translation.Globals.agree}{" "}
                      <span
                        className="trmns"
                        onClick={() => navigate("/terms-&-conditions")}
                      >
                        {" "}
                        {translation.Globals.termsConditions}
                      </span>
                    </p>
                  </FormGroup>
                  <div className="disclaimer">
                    <p>{translation.posts.disclaimer}</p>
                  </div>
                  <div className="btn sbmt_btn">
                    <Button
                      value={translation.Globals.submit}
                      disabled={!formik.values.termsCondition}
                      onClick={() => setError(true)}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={teaserOpen}
        onClose={() => setTeaserOpen(false)}
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="AuthWrap" style={{ paddingTop: 20 }}>
            <div className="cross ">
              <CloseIcon
                style={{ color: "red" }}
                onClick={() => setTeaserOpen(false)}
              />
            </div>
            <h2 style={{ textAlign: "center" }}>{translation.auctionTeaser}</h2>
            <p
              className={setting?.auctionTeaser ? "about_txt" : "about_txt2"}
              dangerouslySetInnerHTML={{
                __html: setting
                  ? setting?.auctionTeaser
                  : translation.Globals.no_info,
              }}
            />
            <div className="flexDiv">
              <Button
                value={translation.okay}
                onClick={() => setTeaserOpen(false)}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </Layout>
  );
};

export default AddAuction;
