import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { Loader, errorToast } from "../../helpers";
import { useLocation, useNavigate } from "react-router-dom";
import { useFilterPostMutation } from "../../service/home";
import useAuth from "../../hooks/useAuth";
import { ListedPost } from "../../types/General";
import { CarPlateCard, Pagination } from "../../components";
import TuneIcon from "@mui/icons-material/Tune";

const SeeAllPage = () => {
  const navigate = useNavigate();
  const user = useAuth();
  const location = useLocation();
  const { state } = location;
  console.log("state: ", state);
  const [filterMutation, { isLoading }] = useFilterPostMutation();
  const [auction, setAuction] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [favItems, setFavItems] = useState<any[]>([]);
  const [likeItems, setLikeItems] = useState<any[]>([]);

  const [filteredPost, setFilteredPost] = useState<ListedPost[]>([]);

  let totalPages = Math.ceil(totalCount / 15);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const checkFavProperties = () => {
    const binaryData = filteredPost || [];
    const fav_data = binaryData
      .filter((item: any) => item?.isWishlistByMe)
      .map((item: any) => item);
    const like_data = binaryData
      .filter((item: any) => item?.isLikedByMe)
      .map((item: any) => item);
    setLikeItems(like_data);
    setFavItems(fav_data);
  };

  const getSeeAll = async () => {
    try {
      const response = await filterMutation({
        type: state?.type,
        limit: 15,
        page: page,
        ...(user?._id ? { userId: user._id } : {}),
      }).unwrap();
      if (response?.statusCode === 200) {
        setFilteredPost(response?.data?.post || []);
        setTotalCount(response?.data?.count);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    checkFavProperties();
  }, [filteredPost]);

  useEffect(() => {
    getSeeAll();
  }, [page, user]);

  useEffect(() => {
    if (state?.type === "auctionAds") {
      setAuction(true);
    }
  }, []);
  return (
    <Layout>
      <Loader isLoad={isLoading} />
      <div className="wrapCls">
        <div className="comment_card">
          <div className="container">
            <div className="prodcts_sc">
              <div className="filterIcon">
                <h1 className="prdct_dtl_hdng">{state?.key || ""}</h1>
                <TuneIcon
                  onClick={() => navigate("/filter", { state: state?.type })}
                />
              </div>

              <div className="crds_sec_see_all">
                {filteredPost?.length
                  ? filteredPost?.map((item) => (
                      <CarPlateCard
                        Auction={auction}
                        category={item?.categoryData?.categoryName}
                        posttitle={item?.posttitle}
                        price={
                          state?.type === "offerPosts"
                            ? item?.offerPrice
                            : item?.price
                        }
                        carPlatenumber={item?.carPlatenumber}
                        _id={item?._id}
                        setFavItems={setFavItems}
                        favItems={favItems}
                        item={item}
                        likeItems={likeItems}
                        setLikeItems={setLikeItems}
                        // role={item?.role}
                      />
                    ))
                  : null}
              </div>

              {filteredPost?.length ? (
                <Pagination
                  module={filteredPost}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                  setPage={setPage}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SeeAllPage;
