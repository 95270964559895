import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { generateResponsiveStyle } from "../utils/AuthModalStyle";
import CloseIcon from "@mui/icons-material/Close";
import { isNumber } from "../utils/validation";
import Button from "./Button";
import { SeeBidders } from "../types/General";
import useTranslation from "../hooks/Translation";

type props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setBidPlaced: Dispatch<SetStateAction<boolean>>;
  currentBid: number;
  bidAmount: string;
  setBidAmount: Dispatch<SetStateAction<string>>;
  handleAddBid: () => void;
  allBidders: SeeBidders[];
};

const data = [
  {
    id: 1,
    card_img: "/static/images/visa.png",
    card_number: "•••• •••• •••• 4432",
    description: "Personal - 06/23",
  },
];

const PlaceBidModal = ({
  open,
  setOpen,
  setBidPlaced,
  currentBid,
  setBidAmount,
  bidAmount,
  handleAddBid,
  allBidders,
}: props) => {
  const style = generateResponsiveStyle();
  const translation = useTranslation();
  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="logout_mdl"
      >
        <Box sx={style}>
          <div className="make_an_offer">
            <div className="cross">
              <CloseIcon onClick={() => setOpen(false)} />
            </div>
            <div className="box" style={{ marginTop: "10px" }}>
              {allBidders?.length ? (
                <h3>
                  {translation.bids.currentBid}{" "}
                  <span className="">
                    {currentBid || "0"} {translation.Globals.sar}
                  </span>
                </h3>
              ) : null}

              <h2
                style={{
                  marginBottom: "15px",
                  fontSize: "22px",
                  textAlign: "center",
                }}
              >
                {translation.bids.weBid}
              </h2>

              <div className="make_ofr">
                <TextField
                  className="ofr_inpt"
                  id="standard-basic"
                  placeholder="1000 SAR"
                  variant="standard"
                  value={bidAmount}
                  inputProps={{ maxLength: 8 }}
                  onChange={(e) => setBidAmount(e.target.value)}
                />
              </div>
              {/* <ul className="cards_sec">
                {data?.map((item) => {
                  return (
                    <li className="mn_crds">
                      <div className="crd_dtls">
                        <figure>
                          <img src={item?.card_img} alt="" />
                        </figure>
                        <div className="dtl_mn">
                          <h4>{item?.card_number || ""}</h4>
                        </div>
                        <ArrowForwardIosIcon />
                      </div>
                    </li>
                  );
                })}
              </ul> */}
              {allBidders?.length ? (
                <p className="refund2">
                  {translation.bids.placeBid1} US {currentBid + 1 || "0"}{" "}
                  {translation.bids.orhigher} SAR
                </p>
              ) : null}

              <Button value="Confirm bid" onClick={handleAddBid} />

              <p className="refund">{translation.bids.disclaimer}</p>

              {allBidders?.length ? (
                <div className="offer_div">
                  <ul>
                    <li
                      onClick={() =>
                        setBidAmount(Math.floor(currentBid * 1.1).toString())
                      }
                    >
                      <span className="">{Math.floor(currentBid * 1.1)}</span>{" "}
                      {translation.Globals.sar}
                    </li>
                    <li
                      onClick={() =>
                        setBidAmount(Math.floor(currentBid * 1.2).toString())
                      }
                    >
                      <span className="">{Math.floor(currentBid * 1.2)}</span>{" "}
                      {translation.Globals.sar}
                    </li>
                    <li
                      onClick={() =>
                        setBidAmount(Math.floor(currentBid * 1.3).toString())
                      }
                    >
                      <span className="">{Math.floor(currentBid * 1.3)}</span>{" "}
                      {translation.Globals.sar}
                    </li>
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default PlaceBidModal;
